export const pastprojects = [{
  id: 0,
  name: 'Estuary',
  description: 'Estuary is a platform for collaboration and learning through live coding. It enables you to experiment with sound, music, and visuals in a web browser. Estuary brings together a curated collection of live coding languages in a single environment, without the requirement to install software (other than a web browser), and with support for networked ensembles (whether in the same room or distributed around the world). Estuary is free and open source software, released under the terms of the GNU Public License (version 3).',
  link: 'https://estuary.mcmaster.ca/',
  image: <img src={require('./static/img/estuary.png')} style={{ maxWidth: '7vw', height: 'auto' }} class="rotate"/>
}, {
  id: 1,
  name: 'tomarsh.ca',
  description: 'To Marsh is a web-based project that acts as a space for nurturing the difference of meaning, experiences and creative expressions resulting from our (Andrea and I) visits to the land currently known as Cootes Paradise. This project contains written and audio-visual responses, as well as propositions for the visitor to take with them and activate wherever in collectivity.',
  link: 'https://tomarsh.ca/',
  image: <img src={require('./static/img/toMarsh.png')} style={{ maxWidth: '7vw', height: 'auto' }} class="rotate"/>
},

{ 
  id: 2,
  name: 'websites',
  description: (
<>
  <div> Over the past few years I have been building and deploying websites for local business and art projects. Here are a few examples.</div>

<hr style={{width: "10vw"}}></hr>
<br></br>

<div className="websites"> 
  <img src={require('./static/img/websites/nyieden.png')} style={{ maxWidth: '40vw', height: 'auto' }} />
  <img src={require('./static/img/websites/wde.png')} style={{ maxWidth: '40vw', height: 'auto' }} />
  <img src={require('./static/img/websites/combjelly.png')} style={{ maxWidth: '40vw', height: 'auto' }} />
  <img src={require('./static/img/websites/tomarsh.png')} style={{ maxWidth: '40vw', height: 'auto' }} />
  <img src={require('./static/img/websites/tomarsh2.png')} style={{ maxWidth: '40vw', height: 'auto' }}/>
  <img src={require('./static/img/websites/combjelly2.png')} style={{ maxWidth: '40vw', height: 'auto' }}/>
  <img src={require('./static/img/websites/wde2.png')} style={{ maxWidth: '40vw', height: 'auto' }} />

</div>
</>
)
}

];

export const about = {
  description:(

  <p> Hi! How are you? I'm well. This is my portfolio. 
  I am a Software Developer with a diverse experience in education, 
  communcation and stretegy development. My work often times revolves 
  around the use of functional programming languages to develop tools to help 
  people bring their visions to life. 

  In my spare time I make <u> <a href="https://cr0w.org/Nicolas-Brown-Hernandez"> collage</a></u> artwork.</p>
  ),
};
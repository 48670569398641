export const Data = {
  profile: {
    name: "Nicholas Brown-Hernandez",
    ocupation: "Software Engineer |  Educator | Artist ",
    location: "Toronto, Canada",
    email: "nicholas.brown2012@gmail.com",
    telephone: "514-889-0894",
    image: "images/me.jpeg",
  },
  aboutMe: {
    label: "Profile",
    description:
      "Skilled Software Developer with a diverse experience in education, communication and strategy development. My work often times revolves around the use of functional programming languages to develop tools to help people bring their visions to life.",
  },
  skills: {
    technicalLabel: "Technology",
    softLabel: "Skills",
    technicalSkills: [
      "Haskell",
      "React",
      "Javascript",
      "Purescript",
      "Python",
      "Git",
      "Pure Data",
      "SQL",
    ],
    softSkills: [
      "Communications",
      "Strategy Development",
      "Lateral Thinking",
      "Problem Solving",
      "Community Development",
      "Teaching",
      "Autonomous",
    ],
  },
  socialMedia: {
    label: "SOCIAL",
    social: [
      {
        label: "Nicholas",
        name: "linkedin",
        url: "https://www.linkedin.com/in/nicholas-brown-hernandez-674b69185/",
        className: "bxl-linkedin-square",
      },
      {
        label: "Nicholas",
        name: "github",
        url: "https://github.com/combjellly",
        className: "bxl-github",
      },

    ],
  },
  experience: {
    works: [
      {
        title: "Research Assistant",
        period: "Aug. 2021 - Present",
        company: "McMaster University",
        description: [
          "Programming and Maintaining Estuary, a web based media live coding interface",
          "Working in Haskell, Javascript, HTML and CSS. Heavy use of Functional Reactive Programming.",
          "Developed chat and polling widgets to allow musicians to talk and vote on custom poll options in real time during their performance. "
        ],
      },
      {
        title: "College Educator",
        period: "Sep. 2022 - present",
        company: "Champlain College",
        description: [
          "Teaching in the media arts department to first and second year college students. Mainly intermedia art, aesthetics, art history and music",
          "Class content ranges from javascript, genre theory, music theory and aesthetic development.",
          "Classes Taught: Web & FX: From Theory to Practice, Expression in Visual Genres,Gods, Goddesses and Warriors: Creative Expression & Music Back and Forth."
        ],
      },
    ],
    academic: [
      {
        career: "Masters in Communications and New Media",
        date: "2021- 2022",
        institution: "McMaster University",
      },
           {
        career: "Bachelors in Electroacoustic Studies",
        date: "2014-2017",
        institution: "Concordia University",
      },
            {
        career: "Cinema and TV Production",
        date: "2012-2014",
        institution: "Champlain College St-Lambert",
      },
    ],
    proyects: [
      {
        name: "DOP | CG Artist | AI Consultant & Engineer",
        company: "NFB",
        period: "Dec. 2021 - present",
        description: [
          "Designing and modelling 3D background environments to be overlaid with hand drawn animations from animator Alex Boya.",
          "Working in Blender & scripting in Python. Creating custom shaders to emulate claymation.",
          "Aided in developing a production pipeline to include AI (DAIN & CLIP)",

        ],
      },
      {
        name: "Shift",
        company: "Personal",
        period: "Aug. 2021 - Present",
        description: [
          "Lead developer on web based live coding language intended on teaching students to code and make music concurrently",
          "Developed in Purescript",
          "https://shift.combjelly.ca/",
        ],
      },
      {
        name: "Syrinx",
        company: "Personal",
        period: "Oct. 2021 - Present",
        description: [
          "Syrinx is an open source and modular method of converting analog resistances to digital floats using a raspberry pi. Intentionally, it was designed as an attempt to create a low-tech and affordable equivalent of expensive hardware.",
        ],
      }
    ],
  },
};

export const projects = [{
  id: 0,
  name: 'Shift',
  description: 'Shift is a live coding language designed to help students learn programming and music concurrently. Research and process documentation can be found in the accompanying thesis, published in 2022:',
  link: 'http://hdl.handle.net/11375/28305',
  linkDescription: "Thesis",
  image: <img src={require('./static/img/shiftLogo.png')} style={{ maxWidth: '7vw', height: 'auto' }} class="rotate"/>
}, {
  id: 1,
  name: 'The Mill',
  description: 'The Mill is a multipart animation series directed by Alex Boya. Bread Will Walk (BWW), the most recent Mill project, is produced by the National Film Board. With the team, I helped develop research methods of blending forground handdrawn animations and 3D rendered background using specialized AI pipelines and custom Shaders. ',
  link: 'http://themill.world',
  image: <img src={require('./static/img/mill.png')} style={{ maxWidth: 'auto', height: '7vw' }} class="rotate"/>
}, {
  id: 2,
  name: 'Teaching',
  description: 'Co-Coordinator of the iCan Program at Heritage Regional High School. College educator for the Digital Arts and New Media department at Champlain College. Online curriculum & resource portfolio coming soon',
}];

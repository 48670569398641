import { useState } from 'react';
import { projects } from './present.js';
import { about } from './about.js';
import { contact } from './contact.js';
import { Resume } from './Pages/Resume'
import { pastprojects } from './past.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

if (window?.location.pathname === '/')
    require('./style.css')
else if(window?.location.pathname === '/resume') 
    require('./App.css')



function ListProjects() {
  const [selectedProject, setSelectedProject] = useState(null);

  let content = null;

  if (selectedProject === 'about') {
    content = 
      <div>
        <div className="projectName">About</div>
        {about.description}
      </div>

  } else if (selectedProject) {
    content = (
      <div>
       {selectedProject.image}
        <div className="projectName">{selectedProject.name}</div>
        <p>{selectedProject.description}</p>
        <a href={selectedProject.link}> {selectedProject.link} </a>
      </div>
    );
  } else {
    content = (
      <div>
        <h2></h2>
      </div>
    );
  }

  const listItems = projects.map(project => (
    <p className="text" key={project.id}>
      <b> &gt;</b>
      <a href="#" onClick={() => setSelectedProject(project)}>
        <b style={{ color: '#5654cc' }}> {project.name} </b>
      </a>
    </p>
  ));

  const listPastItems = pastprojects.map(project => (
    <p className="text" key={project.id}>
      <b> &gt;</b>
      <a href="#" onClick={() => setSelectedProject(project)}>
        <b style={{ color: '#5654cc' }}> {project.name} </b>
      </a>
    </p>
  ));

  const toolBar = (
    <div className="toolBar">
      <a href="#" onClick={() => setSelectedProject('about')}>
        About
      </a> 
      {' <> '} <a href="https://cv.nickhernandez.ca"> Contact</a>
    </div>
  );

const Future = (

  <div className="text">
        <b>&gt; </b>
        <a href="mailto:nicholas.brown2012@gmail.com">
        <b style={{ color: '#5654cc' }}> ? </b>
        </a>
  </div>
  )

  return (
    <div>
      {toolBar}
      <div className="container">
        <h1>Present</h1>
        <hr style={{ width: '25%', textAlign: 'right', marginLeft: '75%', marginTop: '-1.2vw' }} />
        <ul>{listItems}</ul>
        <h1>Past</h1>
        <hr style={{ width: '25%', textAlign: 'right', marginLeft: '75%', marginTop: '-1.2vw' }} />
        <ul>{listPastItems}</ul>
        <h1>Future</h1>
        <hr style={{ width: '25%', textAlign: 'right', marginLeft: '75%', marginTop: '-1.2vw' }} />
        <ul>{Future}</ul>
      </div>
      <div className="mainContent">{content}</div>
    </div>
  );
}


function Header() {
  return (
    <div className="header">
      Nicholas <br></br>
      Brown-Hernandez
      <hr style={{ width: '145%', textAlign: 'left', marginRight: '75%',marginTop: '10%',  height: "0.4vw", borderOutline: "dotted" }}/>
    </div>
  );
}

function Page() {
  return (
    <div>
      <Header />
      <ListProjects />
    </div>
  );
}

function App(){ 
   return ( 
      <div className="App"> 
      <p>
        <Routes> 
            <Route path="/resume" element={<Resume/> } /> 
            <Route path="/" element={<Page/> } /> 
       </Routes> 
       </p>
    </div> 
)} 

export default App;


